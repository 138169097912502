<template>
  <v-row justify="start">
    <v-dialog v-model="dialogQuest" scrollable persistent max-width="600px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <p class="headline ma-0">Edit Question</p>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="px-2" v-if="dataQuestion">
          <v-container class="ma-0 pa-0">
            <section class="mt-3">
              <v-card class="radius-card px-5 pt-5">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      label="pertanyaan"
                      v-model="dataQuestion.soal"
                      dense
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="4">
                    <v-select
                      solo
                      :items="item"
                      item-text="state"
                      item-value="abbr"
                      v-model="dataQuestion.tipe"
                      label="position"
                      hide-details
                      dense
                    ></v-select>
                  </v-col> -->
                </v-row>
                <div v-if="dataQuestion.tipe == 'single'">
                  <section
                    class="mt-5 d-flex"
                    v-for="(item, idx) in optQuestion"
                    :key="idx"
                  >
                    <v-radio class="mr-3" disabled></v-radio>
                    <v-text-field
                      :label="`Opsi`"
                      v-model="item.key"
                      dense
                      hide-details
                      :id="`opt-${idx}`"
                    ></v-text-field>
                    <v-btn
                      x-small
                      fab
                      color="red"
                      depressed
                      dark
                      @click="delItem(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </section>
                  <section class="d-flex mt-3">
                    <v-btn text class="pl-0" @click="addElmnt">
                      <v-radio class="mr-3" disabled></v-radio>
                      Tambahkan opsi</v-btn
                    >
                  </section>
                </div>
                <div v-if="dataQuestion.tipe == 'multiple'">
                  <section
                    class="mt-5 d-flex"
                    v-for="(item, idx) in optQuestion"
                    :key="idx"
                  >
                    <v-checkbox value disabled></v-checkbox>
                    <v-text-field
                      :label="`Opsi`"
                      v-model="item.key"
                      dense
                      hide-details
                      :id="`opt-${idx}`"
                    ></v-text-field>
                    <v-btn
                      x-small
                      fab
                      color="red"
                      depressed
                      dark
                      @click="delItem(item)"
                      ><v-icon small>mdi-delete</v-icon></v-btn
                    >
                  </section>
                  <section class="d-flex mt-3">
                    <v-btn text class="pl-0" @click="addElmnt">
                      <v-radio class="mr-3" disabled></v-radio>
                      Tambahkan opsi</v-btn
                    >
                  </section>
                </div>
              </v-card>
            </section>
          </v-container>
        </v-card-text>
        <!-- <v-overlay :value="loading" :absolute="true" color="white">
          <v-progress-circular
            color="grey"
            indeterminate
            size="35"
          ></v-progress-circular>
        </v-overlay> -->
        <notifications group="validate" class="mt-2" position="top center" />
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" outlined rounded @click="updateQuestion"
            >Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "updateQuets",
  props: ["dataQuestion", "dialogQuest", "optQuestion"],
  data() {
    return {
      form_model: {},
      loading: false,
      item: [
        { state: "Single Choice", abbr: "single" },
        { state: "Multiple Choice", abbr: "multiple" }
      ]
    };
  },
  methods: {
    delItem(item) {
      let arr = this.optQuestion;
      let index = arr.indexOf(item);
      console.log(index);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    addElmnt() {
      if (this.optQuestion.length) {
        if (this.optQuestion[this.optQuestion.length - 1].key != "") {
          this.optQuestion.push({ key: "" });
        }
      }
    },
    updateQuestion() {
      let pilihan = [];
      this.optQuestion.forEach(el => {
        pilihan.push(el.key);
      });
      pilihan = pilihan.filter(el => {
        return el != null && el != "";
      });
      console.log(pilihan);
      let data = {
        id: this.dataQuestion.id,
        survey_id: this.dataQuestion.survey_id,
        tipe: this.dataQuestion.tipe,
        soal: this.dataQuestion.soal,
        pilihan: pilihan
      };
      this.$store.dispatch("survey/createQuestion", data).then(data => {
        this.$emit("close");
        Swal.fire({
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          position: "top",
          toast: true,
          timer: 3000
        });
      });
    }
  }
};
</script>

<style></style>
